/* eslint-disable no-shadow */
import _ from 'lodash';
import Ajv from 'ajv';
import ajvErrors from 'ajv-errors';
import localize from 'ajv-i18n';

import { getTextComparisonList } from '@/common/utils';
import { JSONPath } from 'jsonpath-plus';
import {
  RADIO_DEMO,
  TEXT_EDITOR_DEMO,
  CHECKBOX_DEMO,
  TEXT_SELECTION_DEMO,
  DRAW_GRAPH_DEMO
} from '@/constants/ComponentPreDataDemo';
import { v4 as uuidv4 } from 'uuid';
import schema from '../schema/index';
import { BackgroundParams, FilterType } from './type';
import imgAnnotation from '../schema/imgAnnotation';

const ajv = new Ajv({ allErrors: true });
ajvErrors(ajv);

export const TYPE_OBJ = {
  MARK: 'mark',
  COL: 'col',
  ROW: 'row',
  STATIC_SHOW: 'staticShow',
  DYNAMIC_SHOW: 'dynamicShow',
  TEXT_EDITOR: 'textEditor',
  RADIO: 'radio',
  MULTI_SELECT: 'multiSelect',
  SORT: 'sort',
  IMAGE_ANNOTATION: 'imgAnnotation',
  TEXT_SELECTION: 'textSelection'
} as Record<string, string>;

/**
 * jsonpath类型
 * jsonPath 入参
 * preViewJsonPath 预设数据
 */
const JSON_PATH_KIND = {
  JSON_PATH: 'jsonPath',
  PRE_VIEW_JSON_PATH: 'preViewJsonPath'
};

/**
 * jsonPath类型 + 组件类型 = 初始化数据的结构
 * 通过不同的类型和组件 支持一个组件可以配置多个种类的jsonpath
 */

const JSON_PATH_KIND_TO_OUT_DATA = {
  [JSON_PATH_KIND.JSON_PATH]: {
    [TYPE_OBJ.MARK]: '',
    [TYPE_OBJ.COL]: '',
    [TYPE_OBJ.ROW]: '',
    [TYPE_OBJ.SORT]: '',
    [TYPE_OBJ.STATIC_SHOW]: '',
    [TYPE_OBJ.DYNAMIC_SHOW]: [{ key: '', value: '' }],
    [TYPE_OBJ.TEXT_EDITOR]: '',
    [TYPE_OBJ.RADIO]: [{ value: '默认选项', options: [] }],
    [TYPE_OBJ.MULTI_SELECT]: '',
    [TYPE_OBJ.IMAGE_ANNOTATION]: '',
    [TYPE_OBJ.TEXT_SELECTION]: ''
  },
  [JSON_PATH_KIND.PRE_VIEW_JSON_PATH]: {
    [TYPE_OBJ.MARK]: '',
    [TYPE_OBJ.COL]: '',
    [TYPE_OBJ.ROW]: '',
    [TYPE_OBJ.SORT]: '',
    [TYPE_OBJ.STATIC_SHOW]: '',
    [TYPE_OBJ.DYNAMIC_SHOW]: [{ key: '', value: '' }],
    [TYPE_OBJ.TEXT_EDITOR]: TEXT_EDITOR_DEMO,
    [TYPE_OBJ.RADIO]: RADIO_DEMO,
    [TYPE_OBJ.MULTI_SELECT]: CHECKBOX_DEMO,
    [TYPE_OBJ.IMAGE_ANNOTATION]: DRAW_GRAPH_DEMO,
    [TYPE_OBJ.TEXT_SELECTION]: TEXT_SELECTION_DEMO
  }
};

// 右侧配置header type对应的文字显示
const TYPE_NAME = {
  [TYPE_OBJ.MARK]: '标注区',
  [TYPE_OBJ.COL]: '列容器',
  [TYPE_OBJ.ROW]: '行容器',
  [TYPE_OBJ.STATIC_SHOW]: '静态文本展示组件',
  [TYPE_OBJ.DYNAMIC_SHOW]: '动态文本展示组件',
  [TYPE_OBJ.TEXT_EDITOR]: '文本编辑器',
  [TYPE_OBJ.RADIO]: '单选',
  [TYPE_OBJ.MULTI_SELECT]: '多选',
  [TYPE_OBJ.SORT]: '排序',
  [TYPE_OBJ.IMAGE_ANNOTATION]: '图像标注组件',
  [TYPE_OBJ.TEXT_SELECTION]: '文本标注组件'
} as Record<string, string>;

// 判断是否可以拖出，主要用来锁定标注区
// 返回true不锁定，返回false锁定
// locationArr拖拽到的位置
export function lockMark(
  parentPos: number[],
  typeArr: string[],
  itemArr: number[],
  item?: any
): boolean {
  // 如果是拖拽的第一层 也就是页面上的一级元素或者是单个组件不锁定
  if (typeArr.length === 1) {
    return true;
  }
  const markIndex = typeArr.indexOf(TYPE_OBJ.MARK);

  // 如果标注区是他本身，说明之前没有标注区，不锁定
  if (markIndex === typeArr.length - 1) {
    return true;
  }
  // 没有在标注区内部，不锁定
  if (markIndex === -1) {
    return true;
  }
  // 因为当前结构第一位一定是标注区，超出了标注区之外，锁定
  if (markIndex > parentPos.length) {
    // 因为当前结构第一位一定是标注区，超出了标注区之外，锁定
    return false;
  }
  // 如果是在同一个标注区内 不是跨级拖拽，不锁定
  if (
    itemArr.slice(0, markIndex + 1).join('-') ===
    parentPos.slice(0, markIndex + 1).join('-')
  ) {
    return true;
  }
  // 接触锁区限制
  return true;
  // return false;
}

// 标注区中的子组件不能插入标注区
export function insertMark(
  typeArr: string[],
  type: string,
  children?: any
): boolean {
  if (type === TYPE_OBJ.MARK && typeArr.includes(TYPE_OBJ.MARK)) {
    return false;
  }
  return true;
}
// 查看插入的标注区内是否已经有了标注区
export function insertDynamic(
  data: any,
  type: any,
  parentPos: number[],
  typeArr: string[],
  itemArr: number[],
  item = {}
): boolean {
  if (typeArr.length === 1) {
    return true;
  }
  const markIndex = typeArr.indexOf(TYPE_OBJ.MARK);
  // 在当前组件中拖拽，不限制
  if (
    itemArr.slice(0, markIndex + 1).join('-') ===
    parentPos.slice(0, markIndex + 1).join('-')
  ) {
    return true;
  }

  if (type === 'dynamicShow') {
    const isDynamic = dynamicShowExit(data, 'dynamicShow');
    if (isDynamic) {
      return false;
    }
  }
  if (type === 'imgAnnotation') {
    const isImgAnnotation = dynamicShowExit(data, 'imgAnnotation');
    if (isImgAnnotation) {
      return false;
    }
  }
  if (type === 'row' || type === 'col') {
    const hasDynamic =
      dynamicShowExit(item, 'dynamicShow') &&
      dynamicShowExit(data[parentPos[0]], 'dynamicShow');
    const hasImgAnnotation =
      dynamicShowExit(item, 'imgAnnotation') &&
      dynamicShowExit(data[parentPos[0]], 'imgAnnotation');
    const isDynamic = dynamicShowExit(data, 'dynamicShow');
    const isImgAnnotation = dynamicShowExit(data, 'imgAnnotation');
    if (hasDynamic && isDynamic) {
      return false;
    }
    if (hasImgAnnotation && isImgAnnotation) {
      return false;
    }
  }
  return true;
}
// 判断组件中是否存在dynamicShow或者
function dynamicShowExit(component: any, type: string): boolean {
  let re = false;
  function recursion(dataTemp: any): any {
    if (dataTemp) {
      for (let i = 0; i < dataTemp.length; i += 1) {
        const subTemp = dataTemp[i];
        if (subTemp?.type === type) {
          re = true;
          return;
        }
        if (subTemp.children) recursion(subTemp.children);
      }
    }
  }
  if (component?.type === type) {
    re = true;
  }
  recursion(component.children);
  return re;
}
const propertyMap: Record<string, string> = {
  jsonPath: '输入路径',
  showName: '组件名称',
  isShowName: '是否展示组件名称',
  outputWord: '输出字段',
  isRequired: '是否必填',
  MarkToolList: '标注工具',
  internalExtension: '内部扩展',
  // supportLatex: '公式编辑器',
  markCustomWidth: '输入宽度',
  supportPreview: '是否支持预览',
  isFormatShowText: '格式渲染',
  showQuestionSearch: '题目查询',
  fontSize: '字号',
  optionSource: '选项来源',
  isShowPushpin: '标注区固定',
  colSpan: '所占份数',
  label: '设置标签'
};
const componentRequire: Record<string, { name: string; required: string[] }> = {
  mark: {
    name: '标注区',
    required: ['isShowPushpin', 'checkValue', 'markCustomWidth']
  },
  col: {
    name: '列容器',
    required: ['colSpan']
  },
  row: {
    name: '行容器',
    required: []
  },
  staticShow: {
    name: '静态文本展示组件',
    required: [
      'jsonPath',
      'showPath',
      'supportPreview',
      'isFormatShowText',
      'showQuestionSearch',
      'fontSize',
      'showName',
      'isShowName'
    ]
  },
  dynamicShow: {
    name: '动态文本展示组件',
    required: [
      'jsonPath',
      'showPath',
      'isFormatShowText',
      'fontSize',
      'supportPreview',
      'showName',
      'isShowName'
    ]
  },
  textEditor: {
    name: '文本编辑器',
    required: [
      'outputWord',
      'internalExtension',
      'isFormatShowText',
      // 'supportLatex',
      'isRequired',
      'showName',
      'isShowName'
    ]
  },
  radio: {
    name: '单选',
    required: [
      'showPath',
      'outputWord',
      'isRequired',
      'optionSource',
      'showName',
      'isShowName'
    ]
  },
  multiSelect: {
    name: '多选',
    required: ['showPath', 'outputWord', 'isRequired', 'showName', 'isShowName']
  },
  imgAnnotation: {
    name: '图像标注',
    required: [
      'jsonPath',
      'showName',
      'outputWord',
      'isRequired',
      'MarkToolList',
      'isCount',
      'isPreViewJsonPath',
      'isShowName',
      'label'
    ]
  },
  textSelection: {
    name: '文本标注组件',
    required: ['jsonPath', 'showName', 'outputWord', 'isShowName', 'label']
  }
};
// 维护组件间的拖拽关系的Map
// Map数组的第二个值为不可拖拽进入的组件type
// 用于维护空白占位区中的拖拽关系
const astrict = new Map([
  [TYPE_OBJ.MARK, [TYPE_OBJ.MARK]],
  [TYPE_OBJ.COL, []],
  [TYPE_OBJ.ROW, []],
  [TYPE_OBJ.STATIC_SHOW, []],
  [TYPE_OBJ.DYNAMIC_SHOW, []],
  [TYPE_OBJ.TEXT_EDITOR, []],
  [TYPE_OBJ.RADIO, []],
  [TYPE_OBJ.MULTI_SELECT, []],
  [TYPE_OBJ.SORT, []],
  [TYPE_OBJ.IMAGE_ANNOTATION, []],
  [TYPE_OBJ.TEXT_SELECTION, []],
  [
    undefined,
    [
      TYPE_OBJ.COL,
      TYPE_OBJ.ROW,
      TYPE_OBJ.STATIC_SHOW,
      TYPE_OBJ.DYNAMIC_SHOW,
      TYPE_OBJ.TEXT_EDITOR,
      TYPE_OBJ.RADIO,
      TYPE_OBJ.MULTI_SELECT,
      TYPE_OBJ.SORT,
      TYPE_OBJ.IMAGE_ANNOTATION,
      TYPE_OBJ.TEXT_SELECTION
    ]
  ] // 最外层的fatherType是undefined，表示最外层的空白区域拖拽限制
]);

// 组件拖拽限制管理 用于空白占位区的拖拽关系限制
// 返回false表示不进行限制可以拖拽 返回true表示进行限制不能拖拽
export function componentsAstrictRelation(
  fatherType: string,
  dragType: string
): boolean {
  return astrict.get(fatherType)?.includes(dragType) || false;
}

// 所有组件的type类型，如果增加了组件，这里的类型也需要增加
// const type = [
//   'mark',
//   'col',
//   'row',
//   'staticShow',
//   'dynamicShow',
//   'textEditor',
//   'multiple',
//   'multiSelect',
//   'sort'
// ];

// 如果没有写use默认使用type exclude用来过滤不想使用的type
export function filterType({ use, exclude }: FilterType = {}): string[] {
  const data = use || Object.values(TYPE_OBJ);
  const set = new Set(exclude || []);
  return data.filter((item) => !set.has(item));
}

// 返回点击的状态和hover状态样式
export function getBackground({
  isOverCurrent,
  clickActive,
  isEdit
}: BackgroundParams): Record<string, any> {
  if (!isEdit) {
    return {
      border: '1px solid #efefef'
    };
  }

  // hover高亮
  if (isEdit && isOverCurrent) {
    return {
      background: '#cedcf7'
    };
  }
  // 点击高亮
  if (isEdit && clickActive) {
    return {
      border: '1px dashed #6787df'
    };
  }
  return {};
}

function isValidJsonPath(path: string): boolean {
  // const jsonPathRegex =
  // /^(?:\$|\@\.)(?:\[\d+\]|\[\*\]|\[['"]?[a-zA-Z0-9_]+['"]?\]|\.[a-zA-Z0-9_]+)*$/;
  const jsonPathRegex = /^\$\.[A-Za-z_\.\\\[\]].*/;
  jsonPathRegex.lastIndex = 0;
  return jsonPathRegex.test(path);
}

function isValidOutputWord(path: string): boolean {
  const outputWordRegex = /^[A-Za-z_][A-Za-z0-9_]*$/;
  outputWordRegex.lastIndex = 0;
  return outputWordRegex.test(path);
}
function isValidShowName(path: string): boolean {
  const showNameRegex = /^.*$/u;
  // /^[\u4e00-\u9fa5A-Za-z0-9，,。\.？?！!：:；;'""“”‘’（）\(\)\[\]【】<>_《》—…、]+$/u;
  showNameRegex.lastIndex = 0;
  return showNameRegex.test(path);
}
// config是否有效
// function isConfigValid(type: any, configData: any): Array<any> {
//   const validate = ajv.compile(schema[type]);
//   const valid = Boolean(validate(configData));
//   if (!valid) {
//     localize.zh(validate?.errors);
//   }
//   const errors = validate?.errors;
//   const message = errors ? errors[0].message : '';
//   let instancePath = errors ? errors[0]?.instancePath : '';
//   instancePath = instancePath.substr(1);
//   if (valid && configData.jsonPath && !isValidJsonPath(configData.jsonPath)) {
//     return [
//       false,
//       `${TYPE_NAME[type]}输入路径有误, 必须$.开头仅支持数字、英文大小写、下划线、[]`
//     ];
//   }
//   if (configData.outputWord && !isValidOutputWord(configData.outputWord)) {
//     return [
//       false,
//       `${TYPE_NAME[type]}输出路径有误, 非数字开头且仅支持英文大小写、下划线、数字`
//     ];
//   }
//   if (configData.showName && !isValidShowName(configData.showName)) {
//     return [
//       false,
//       `${TYPE_NAME[type]}组件名称有误, 仅支持中文、英文大小写、标点符号，数字`
//     ];
//   }
//   const validAllResult = validateAll(type, configData);
//   if (!validAllResult[0]) {
//     return validAllResult;
//   }

//   // 检查预设路径
//   if (!checkPreViewJsonPath(configData)) {
//     return [false, `${TYPE_NAME[type]}【预设路径】内容错误，请修正`];
//   }

//   // 单选固定选项时
//   if (valid && type === 'radio' && configData.optionSource === 'fixed') {
//     const fOption = [];
//     const itemRegex = /^.*$/u;
//     // /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/g;
//     for (let i = 0; i < configData.options.length; i += 1) {
//       if (configData.options[i].value.trim() === '') {
//         return [false, `${TYPE_NAME[type]}一级选项内容缺失`];
//       }
//       itemRegex.lastIndex = 0;

//       if (!itemRegex.test(configData.options[i].value)) {
//         return [false, `${TYPE_NAME[type]}一级选项内容不符合要求`];
//       }
//       fOption.push(configData.options[i].value);
//       // 二级选项
//       for (let j = 0; j < configData.options[i].options.length; j += 1) {
//         if (configData.options[i].options[j].trim() === '') {
//           return [false, `${TYPE_NAME[type]}二级选项内容缺失`];
//         }
//         const secondOpt = configData.options[i].options[j];
//         itemRegex.lastIndex = 0;
//         if (!itemRegex.test(secondOpt)) {
//           return [false, `${TYPE_NAME[type]}二级选项内容不符合要求`];
//         }
//       }
//       if (
//         configData.options[i].options.length !==
//         [...new Set(configData.options[i].options)].length
//       ) {
//         console.warn('单选二级选项有重复');
//         return [false, `${TYPE_NAME[type]}二级选项有重复`];
//       }
//     }
//     // 判断一级选项是否有重复内容
//     if (fOption.length !== [...new Set(fOption)].length) {
//       console.warn('单选一级选项有重复');
//       return [false, `${TYPE_NAME[type]}一级选项有重复`];
//     }
//   }
//   if (type === 'radio' && configData.optionSource === 'noFixed') {
//     // 如果jsonPath为空
//     if (!configData.jsonPath) {
//       return [false, '单选，请输入输入路径'];
//     }
//   }
//   if (valid && type === 'multiSelect') {
//     const itemRegex = /^.*$/u;
//     //  /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/g;
//     for (let i = 0; i < configData.options.length; i += 1) {
//       itemRegex.lastIndex = 0;
//       if (!itemRegex.test(configData.options[i])) {
//         return [false, `${TYPE_NAME[type]}内容不符合要求`];
//       }
//       if (configData.options[i].trim() === '') {
//         return [false, `${TYPE_NAME[type]}多选选项内容缺失`];
//       }
//     }
//     if (configData.options.length !== [...new Set(configData.options)].length) {
//       return [false, `${TYPE_NAME[type]}选项内容重复`];
//     }
//   }
//   return [valid, `${TYPE_NAME[type]}, ${message}`];
// }

const isEmpty = (value: any): boolean => {
  if (value === null || value === undefined || value === '') return true;
  if (typeof value === 'string' && value.trim() === '') return true;
  if (Array.isArray(value) && value.length === 0) return true;
  if (typeof value === 'object' && Object.keys(value).length === 0) return true;
  return false;
};

const validateItem = (type: any, configData: any): Array<any> => {
  const component = componentRequire[type];

  if (!component) {
    return [false, `未知的组件类型：${type}`];
  }

  for (const field of component.required) {
    // 特殊处理 mark 类型和 checkValue 为 custom 的情况
    if (
      type === 'mark' &&
      field === 'markCustomWidth' &&
      configData.checkValue !== 'custom'
    ) {
      // 不检查 markCustomWidth
    } else if (isEmpty(configData[field])) {
      const fieldName = propertyMap[field] || field;
      return [false, `当前组件【${fieldName}】内容错误，请修正`];
    }
  }

  return [true, ''];
};
// const validateAll = (type: any, configData: any): Array<any> => {
//   const component = componentRequire[type];

//   if (!component) {
//     return [false, `未知的组件类型：${type}`];
//   }

//   for (const field of component.required) {
//     // 特殊处理 mark 类型和 checkValue 为 custom 的情况
//     if (
//       type === 'mark' &&
//       field === 'markCustomWidth' &&
//       configData.checkValue !== 'custom'
//     ) {
//       // 不检查 markCustomWidth
//     } else if (isEmpty(configData[field])) {
//       const fieldName = propertyMap[field] || field;
//       return [false, `${TYPE_NAME[type]}【${fieldName}】内容错误，请修正`];
//     }
//   }

//   return [true, ''];
// };
export function isItemConfigValid(
  type: any,
  configData: any,
  isSelf = true
): Array<any> {
  const validate = ajv.compile(schema[type]);
  const valid = Boolean(validate(configData));
  if (!valid) {
    localize.zh(validate?.errors);
  }
  const errors = validate?.errors;
  const message = errors ? errors[0].message : '';
  let instancePath = errors ? errors[0]?.instancePath : '';
  instancePath = instancePath.substr(1);

  const componentName = isSelf ? '当前组件' : TYPE_NAME[type];

  if (valid && configData.jsonPath && !isValidJsonPath(configData.jsonPath)) {
    return [
      false,
      `${componentName}输入路径有误, 必须$.开头仅支持数字、英文大小写、下划线、[]`
    ];
  }

  if (configData.outputWord && !isValidOutputWord(configData.outputWord)) {
    return [
      false,
      `${componentName}输出路径有误, 非数字开头且仅支持英文大小写、下划线、数字`
    ];
  }

  if (configData.showName && !isValidShowName(configData.showName)) {
    return [
      false,
      `${componentName}组件名称有误, 仅支持中文、英文大小写、标点符号，数字`
    ];
  }

  const validItemResult = validateItem(type, configData);
  if (!validItemResult[0]) {
    return validItemResult;
  }

  // 检查预设路径
  if (!checkPreViewJsonPath(configData)) {
    return [false, `${componentName}【预设路径】内容错误，请修正`];
  }

  // 单选固定选项时
  if (valid && type === 'radio' && configData.optionSource === 'fixed') {
    const fOption = [];
    const itemRegex = /^.*$/u;
    // /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/g;
    for (let i = 0; i < configData.options.length; i += 1) {
      if (configData.options[i].value.trim() === '') {
        return [false, `${componentName}一级选项内容缺失`];
      }
      itemRegex.lastIndex = 0;

      if (!itemRegex.test(configData.options[i].value)) {
        return [false, `${componentName}一级选项内容不符合要求`];
      }
      fOption.push(configData.options[i].value);
      // 二级选项
      for (let j = 0; j < configData.options[i].options.length; j += 1) {
        if (configData.options[i].options[j].trim() === '') {
          return [false, `${componentName}二级选项内容缺失`];
        }
        const secondOpt = configData.options[i].options[j];
        itemRegex.lastIndex = 0;
        if (!itemRegex.test(secondOpt)) {
          return [false, `${componentName}二级选项内容不符合要求`];
        }
      }
      if (
        configData.options[i].options.length !==
        [...new Set(configData.options[i].options)].length
      ) {
        console.warn('单选二级选项有重复');
        return [false, `${componentName}二级选项有重复`];
      }
    }
    // 判断一级选项是否有重复内容
    if (fOption.length !== [...new Set(fOption)].length) {
      console.warn('单选一级选项有重复');
      return [false, `${componentName}一级选项有重复`];
    }
  }
  if (type === 'radio' && configData.optionSource === 'noFixed') {
    // 如果jsonPath为空
    if (!configData.jsonPath) {
      return [false, '单选，请输入输入路径'];
    }
  }
  // 多选固定选项
  if (valid && type === 'multiSelect') {
    const itemRegex = /^.*$/u;
    //  /^[\u4e00-\u9fa5a-zA-Z0-9_]+$/g;
    for (let i = 0; i < configData.options.length; i += 1) {
      itemRegex.lastIndex = 0;
      if (!itemRegex.test(configData.options[i])) {
        return [false, `${componentName}内容不符合要求`];
      }
      if (configData.options[i].trim() === '') {
        return [false, `${componentName}多选选项内容缺失`];
      }
    }
    if (configData.options.length !== [...new Set(configData.options)].length) {
      return [false, `${componentName}选项内容重复`];
    }
  }
  return [valid, `${componentName}, ${message}`];
}
// 检查模版是否至少有一个标注组件和一个展示组件
export const checkTemplate = (templateData: any): any => {
  let markComponent = 0;
  let showComponent = 0;
  const checked = (tempdata: any): void => {
    if ((markComponent && showComponent) || !tempdata) return;
    for (let i = 0; i < tempdata.length; i += 1) {
      if (
        tempdata[i].type === 'dynamicShow' ||
        tempdata[i].type === 'staticShow'
      ) {
        showComponent += 1;
      } else if (
        tempdata[i].type === 'radio' ||
        tempdata[i].type === 'multiSelect' ||
        tempdata[i].type === 'textEditor' ||
        tempdata[i].type === 'imgAnnotation' ||
        tempdata[i].type === 'sort' ||
        tempdata[i].type === 'textSelection'
      ) {
        markComponent += 1;
      } else {
        checked(tempdata[i].children);
      }
    }
  };
  checked(templateData);
  if (markComponent && showComponent) return { result: true, message: '' };
  return { result: false, message: '' };
};

// 检查单条模版数据
export function checkOneTemplateData(templateData: any, item: any): any {
  let returnResult = { result: true, message: '' };
  if (!item) return returnResult;
  // 如果存在config
  if (item && item.config) {
    const isValidRes = isItemConfigValid(item.type, item.config, true);
    const validNum = isValidRes[0];
    const error = isValidRes[1];
    if (!validNum) {
      // 检查config是否符合要求
      // 如果不符合要求，打印出这条数据并中止这个递归调用
      returnResult = {
        result: false,
        message: error
      };
      return returnResult; // 中止当前递归调用
    }
  }
  // if (item && item.type === 'textSelection') {
  //   const checkLableListStr = checkLableList(item.config);
  //   if (!checkLableListStr) {
  //     returnResult = {
  //       result: false,
  //       message: '当前组件【设置标签】内容错误，请修正'
  //     };
  //     return returnResult;
  //   }
  // }
  if (item && item.type === 'imgAnnotation') {
    const checkMarkListStr = checkMarkList(item.config);
    if (!checkMarkListStr) {
      returnResult = {
        result: false,
        message: '当前组件【标注工具】内容错误，请修正'
      };
      return returnResult;
    }
    // const checkLableListStr = checkLableList(item.config);
    // if (!checkLableListStr) {
    //   returnResult = {
    //     result: false,
    //     message: '当前组件【设置标签】内容错误，请修正'
    //   };
    //   return returnResult;
    // }
    const checkAttachTagsStr = checkAttachTags(item.config);
    if (!checkAttachTagsStr) {
      returnResult = {
        result: false,
        message: '当前组件【附加标签】内容错误，请修正'
      };
      return returnResult;
    }
    // const checkPreViewJsonPathStr = checkPreViewJsonPath(item.config);
    // if (!checkPreViewJsonPathStr) {
    //   returnResult = {
    //     result: false,
    //     message: '当前组件【预设路径】内容错误，请修正'
    //   };
    //   return returnResult;
    // }
  }
  // 标注组件和展示组件
  if (item.type !== 'mark' && item.type !== 'col' && item.type !== 'row') {
    // 校验所有输出字段是否有重复
    const checkOutputWord = checkSpecificItemOutputWord(item, templateData);
    if (!checkOutputWord) {
      returnResult = {
        result: false,
        message: '当前组件【输出字段】内容错误，请修正'
      };
      return returnResult;
    }
    const checkShowName = checkSpecificItemShowName(item, templateData);
    if (!checkShowName) {
      returnResult = {
        result: false,
        message: '当前组件【组件名称】内容错误，请修正'
      };
      return returnResult;
    }

    // // 检查预设路径
    // const checkPreViewJsonPathStr = checkPreViewJsonPath(item.config);
    // if (!checkPreViewJsonPathStr) {
    //   returnResult = {
    //     result: false,
    //     message: '当前组件【预设路径】内容错误，请修正'
    //   };
    //   return returnResult;
    // }
  }
  return returnResult;
}

const checkSpecificItemOutputWord = (item: any, templateData: any[]): any => {
  const itemOutputWords: string[] = checkOutputWord(item, []);
  let allOtherOutputWords: string[] = [];

  for (let i = 0; i < templateData.length; i += 1) {
    const list = checkOutputWord(templateData[i], []);
    allOtherOutputWords = [...allOtherOutputWords, ...list];
  }
  let matchCount = 0;
  for (const word of allOtherOutputWords) {
    if (word === itemOutputWords[0]) {
      matchCount += 1;
      if (matchCount > 1) {
        return false;
      }
    }
  }
  return true;
};
const checkSpecificItemShowName = (item: any, templateData: any[]): any => {
  const itemShowNames: string[] = checkShowName(item, []);
  let allOtherShowNames: string[] = [];

  for (let i = 0; i < templateData.length; i += 1) {
    const list = checkShowName(templateData[i], []);
    allOtherShowNames = [...allOtherShowNames, ...list];
  }
  let matchCount = 0;
  for (const word of allOtherShowNames) {
    if (word === itemShowNames[0]) {
      matchCount += 1;
      if (matchCount > 1) {
        return false;
      }
    }
  }

  return true;
};

// 检查模版数据
export function checkTemplateData(templateData: any): any {
  let returnResult = { result: true, message: '' };
  for (let i = 0; i < templateData.length; i += 1) {
    const item = templateData[i];
    // 如果存在config
    if (item.config) {
      const isValidRes = isItemConfigValid(item.type, item.config, false);
      const validNum = isValidRes[0];
      const error = isValidRes[1];
      if (!validNum) {
        // 检查config是否符合要求
        // 如果不符合要求，打印出这条数据并中止这个递归调用
        returnResult = {
          result: false,
          message: error
        };
        return returnResult; // 中止当前递归调用
      }
    }
    // if (item && item.type === 'textSelection') {
    //   const checkLableListStr = checkLableList(item.config);
    //   if (!checkLableListStr) {
    //     returnResult = {
    //       result: false,
    //       message: '当前组件【设置标签】内容错误，请修正'
    //     };
    //     return returnResult;
    //   }
    // }
    if (item && item.type === 'imgAnnotation') {
      const checkMarkListStr = checkMarkList(item.config);
      if (!checkMarkListStr) {
        returnResult = {
          result: false,
          message: `${TYPE_NAME[item.type]}【标注工具】内容错误，请修正`
        };
        return returnResult;
      }
      // const checkLableListStr = checkLableList(item.config);
      // if (!checkLableListStr) {
      //   returnResult = {
      //     result: false,
      //     message: `${TYPE_NAME[item.type]}【设置标签】内容错误，请修正`
      //   };
      //   return returnResult;
      // }
      const checkAttachTagsStr = checkAttachTags(item.config);
      if (!checkAttachTagsStr) {
        returnResult = {
          result: false,
          message: `${TYPE_NAME[item.type]}【附加标签】内容错误，请修正`
        };
        return returnResult;
      }
      const checkPreViewJsonPathStr = checkPreViewJsonPath(item.config);
      if (!checkPreViewJsonPathStr) {
        returnResult = {
          result: false,
          message: `${TYPE_NAME[item.type]}【预设路径】内容错误，请修正`
        };
        return returnResult;
      }
    }
    // 如果config符合要求，并且存在children，继续递归检查
    if (item.children && item.children.length > 0) {
      const childrenResult = checkTemplateData(item.children); // 递归检查children
      if (!childrenResult.result) {
        returnResult = { result: false, message: childrenResult.message };
        return returnResult;
      }
    }
  }
  // 校验所有输出字段是否有重复
  let allList: any = [];
  let allListShowName: any = [];
  for (let i = 0; i < templateData.length; i += 1) {
    const list = checkAllOutputWord(templateData[i], []);
    allList = [...allList, ...list];
  }
  const duplicateOutputWord = findDuplicate(allList);
  if (duplicateOutputWord) {
    returnResult = {
      result: false,
      message: `${duplicateOutputWord.title}【输出字段】内容错误，请修正`
    };
    return returnResult;
  }
  for (let i = 0; i < templateData.length; i += 1) {
    const list = checkAllShowName(templateData[i], []);
    allListShowName = [...allListShowName, ...list];
  }
  const duplicateShowName = findDuplicate(allListShowName);
  if (duplicateShowName) {
    returnResult = {
      result: false,
      message: `${duplicateShowName.title}【组件名称】内容错误，请修正`
    };
    return returnResult;
  }
  return returnResult;
}
// 检查数据的输出字段是否有重复
const checkAllOutputWord = (data: any, list: any[]): any => {
  if (data.config && data.config.outputWord) {
    list.push({ value: data.config.outputWord, title: data.title });
  }
  if (data.children) {
    for (let i = 0; i < data.children.length; i += 1) {
      list = checkAllOutputWord(data.children[i], list);
    }
  }
  return list;
};

// 检查数据的组件名称是否有重复
const checkAllShowName = (data: any, list: any[]): any => {
  if (data.config && data.config.showName) {
    list.push({ value: data.config.showName, title: data.title });
  }
  if (data.children) {
    for (let i = 0; i < data.children.length; i += 1) {
      list = checkAllShowName(data.children[i], list);
    }
  }
  return list;
};

// 查找重复项
const findDuplicate = (list: any[]): any => {
  const seen = new Map();
  for (const item of list) {
    if (seen.has(item.value)) {
      return item;
    }
    seen.set(item.value, true);
  }
  return null;
};
// 检查数据的输出字段是否有重复
const checkOutputWord = (data: any, list: string[]): any => {
  if (data.config && data.config.outputWord) {
    list.push(data.config.outputWord);
  }
  if (data.children) {
    for (let i = 0; i < data.children.length; i += 1) {
      list = checkOutputWord(data.children[i], list);
    }
  }
  return list;
};

// 检查数据的组件名称是否有重复
const checkShowName = (data: any, list: string[]): any => {
  if (data.config && data.config.showName) {
    list.push(data.config.showName);
  }
  if (data.children) {
    for (let i = 0; i < data.children.length; i += 1) {
      list = checkShowName(data.children[i], list);
    }
  }
  return list;
};

// 检查图像标注组件 标注工具
const checkMarkList = (obj: any): any => {
  if (obj && Array.isArray(obj.MarkToolList)) {
    return obj.MarkToolList.length > 0;
  }
  return false;
};

// 检查图像标注组件 标签
const checkLableList = (obj: any): any => {
  if (!obj || !Array.isArray(obj.label)) {
    return false;
  }
  if (obj.label.length === 0) {
    return false;
  }
  const nameSet = new Set();
  const colorSet = new Set();
  for (const item of obj.label) {
    // 检查每个 item 是否包含 name 和 color 字段
    if (!item.name || !item.color) {
      return false;
    }
    // 检查 name 和 color 是否唯一
    if (nameSet.has(item.name) || colorSet.has(item.color)) {
      return false;
    }
    nameSet.add(item.name);
    colorSet.add(item.color);
  }

  return true;
};
// 检查图像标注组件 附加标记
const checkAttachTags = (obj: any): any => {
  // 检查 obj 是否存在，并且 attachTags 字段是否是对象
  if (!obj || typeof obj.attachTags !== 'object') {
    return false;
  }
  const attachTags = obj.attachTags;
  const validFields = ['radio', 'checkbox', 'input'];
  const titleSet = new Set();
  for (const key in attachTags) {
    if (Object.prototype.hasOwnProperty.call(attachTags, key)) {
      if (!validFields.includes(key)) {
        return false;
      }
      const items = attachTags[key];
      if (!Array.isArray(items)) {
        return false;
      }
      for (const item of items) {
        if (
          typeof item.isRequired !== 'number' ||
          typeof item.title !== 'string'
        ) {
          return false;
        }
        if (titleSet.has(item.title)) {
          return false;
        }
        titleSet.add(item.title);
        if (key === 'input') {
          if (typeof item.forbidNumber !== 'number') {
            return false;
          }
        } else {
          if (!Array.isArray(item.options)) {
            return false;
          }
          const optionsSet = new Set();
          for (const option of item.options) {
            if (optionsSet.has(option)) {
              return false;
            }
            optionsSet.add(option);
          }
        }
      }
    }
  }

  return true;
};

// 检查预设路径
const checkPreViewJsonPath = (obj: any): any => {
  if (obj.isPreViewJsonPath === 1) {
    // 检查 preViewJsonPath 是否有值
    if (!obj.preViewJsonPath) {
      return false;
    }
    const regex = /^\$\.[A-Za-z_\.\\\[\]].*/;
    if (!regex.test(obj.preViewJsonPath)) {
      return false;
    }
  }

  return true;
};

export function getJsonPathList(templateData: any): any {
  let jsonPathList: any = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < templateData.length; i++) {
    const item = templateData[i];
    if (item.config && Object.hasOwn(item.config, JSON_PATH_KIND.JSON_PATH)) {
      const { jsonPath } = item.config;
      jsonPathList.push({
        path: jsonPath,
        type: item.type,
        kind: JSON_PATH_KIND.JSON_PATH
      });
    }
    if (
      item.config &&
      Object.hasOwn(item.config, JSON_PATH_KIND.PRE_VIEW_JSON_PATH)
    ) {
      const { preViewJsonPath } = item.config;
      jsonPathList.push({
        path: preViewJsonPath,
        type: item.type,
        kind: JSON_PATH_KIND.PRE_VIEW_JSON_PATH
      });
    }
    if (item.children && item.children.length > 0) {
      const list = getJsonPathList(item.children);
      jsonPathList = [...jsonPathList, ...list];
    }
  }
  return jsonPathList;
}

function jsonPathToStructure(
  jsonPath: string,
  type: string,
  kind: string
): any {
  if (!jsonPath) return {};
  // 移除开头的$和括号内的通配符[*]
  const normalizedPath = jsonPath.replace(/^\$\./, '');

  // 分割路径
  const keys = normalizedPath.split('.');

  const regex = /(\[\d\]|\[\*\])$/;

  // 递归构建JSON结构
  const buildStructure = (keys: any, isArray: boolean, type: string): any => {
    if (keys.length === 0) {
      let endData: any = '';

      endData = _.cloneDeep(JSON_PATH_KIND_TO_OUT_DATA[kind][type]) || '';

      // switch (type) {
      //   case 'staticShow':
      //     endData = '';
      //     break;
      //   case 'dynamicShow':
      //     endData = [{ key: '', value: '' }];
      //     break;
      //   case 'radio':
      //     endData = [{ value: '默认选项', options: [] }];
      //     break;
      // }

      return endData;
    }

    // 移除并获取当前的key
    let key = keys.shift();
    // 检查下一个键是否表示数组
    const currentIsArray = regex.test(key);
    // 递归构建剩余的结构
    const value = buildStructure(keys, currentIsArray, type);
    // 构建当前层级的对象
    const obj: any = {};

    // 清除key中的数组标记
    if (currentIsArray) {
      key = key.replace(regex, '');
      obj[key] = [value];
    } else {
      obj[key] = value;
    }
    return obj;
  };

  // 构建JSON结构
  return buildStructure(keys, false, type);
}

export function jsonPathReverseJson(templateData: any): any {
  if (templateData.length <= 0) return;
  const jsonPathList = getJsonPathList(templateData);
  const jsonPathObjArray: any = [];
  jsonPathList.forEach((item: any) => {
    const { type, path, kind } = item;
    const result = jsonPathToStructure(path, type, kind);
    jsonPathObjArray.push(result);
  });
  const mergedObject = _.merge({}, ...jsonPathObjArray);
  // eslint-disable-next-line consistent-return
  return mergedObject;
}

// 找到动态组件
export function findDynamicShow(parentRoot: any): any {
  if (!parentRoot) return parentRoot;
  let dynamicShow = null;
  if (parentRoot.type === TYPE_OBJ.DYNAMIC_SHOW) {
    return parentRoot;
  }
  function findDynamicShowNode(temp: any[]): void {
    temp = Array.isArray(temp) ? temp : [];
    temp.forEach((item) => {
      if (item.type === TYPE_OBJ.DYNAMIC_SHOW) {
        dynamicShow = item;
      } else {
        findDynamicShowNode(item.children);
      }
    });
  }
  findDynamicShowNode(parentRoot);
  return dynamicShow;
}

export function getInitOutPutList(templateData: any): any {
  let jsonPathList: any = [];
  const markAreaList: any = [];
  for (let i = 0; i < templateData.length; i += 1) {
    const item = templateData[i];
    if (
      item.config &&
      (Object.hasOwn(item.config, 'jsonPath') ||
        Object.hasOwn(item.config, 'outputWord') ||
        Object.hasOwn(item.config, 'key'))
    ) {
      const { jsonPath, outputWord, isRequired, showName, isCount } =
        item.config;
      const { key } = item;
      jsonPathList.push({
        path: jsonPath,
        type: item.type,
        outputWord,
        key,
        isRequired,
        name: showName,
        isCount
      });
    }
    if (item.children && item.children.length > 0) {
      const { jsonPathList: list } = getInitOutPutList(item.children);
      jsonPathList = [...jsonPathList, ...list];
    }

    if (item.type === 'mark') {
      markAreaList.push(jsonPathList);
      jsonPathList = [];
    }
  }
  return { jsonPathList, markAreaList };
}

// 初始化导出的json数据
export function initOutPutJson(templateData: any): any {
  if (templateData.length <= 0) return;
  const { jsonPathList: outputList, markAreaList } =
    getInitOutPutList(templateData);

  const res = markAreaList.map((itemList: any) => {
    const jsonPathObjArray: any = [];
    // let staticShowIdx = 0;
    // let dynamicShowIdx = 0;
    itemList.forEach((item: any) => {
      const { type, path, outputWord, key, isRequired, name, isCount } = item;
      let obj = {};
      // 通用属性
      const commonProps = { key, type, isRequired, name };
      switch (type) {
        // todo 临时去掉静态和动态文本展示的出参整合
        // case 'staticShow':
        //   staticShowIdx += 1;
        //   obj = {
        //     [`staticShow-${staticShowIdx}`]: {
        //       key,
        //       type,
        //       value: '',
        //       isRequired
        //     }
        //   };
        //   break;
        // case 'dynamicShow':
        //   dynamicShowIdx += 1;
        //   obj = {
        //     [`dynamicShow-${dynamicShowIdx}`]: {
        //       key,
        //       type,
        //       value: '',
        //       isRequired
        //     }
        //   };
        //   break;
        case 'radio':
          obj = {
            [outputWord]: {
              key,
              type,
              value: '',
              children: '',
              isRequired,
              name
            }
          };
          break;
        case 'multiSelect':
          obj = { [outputWord]: { key, type, value: [''], isRequired, name } };
          break;
        case 'textEditor':
          obj = {
            [outputWord]: {
              key,
              type: 'textEditor',
              isRequired,
              name,
              value: [
                {
                  name: '段落',
                  children: [
                    {
                      name: '小节1',
                      value: '',
                      originValue: '',
                      id: ''
                    }
                  ]
                }
              ]
            }
          };
          break;
        case 'imgAnnotation':
          obj = {
            [outputWord]: {
              ...commonProps,
              // type: 'imgAnnotation',
              // name,
              // key,
              // isRequired,
              isCount,
              value: []
            }
          };
          break;
        case 'textSelection':
          obj = {
            [outputWord]: {
              ...commonProps,
              // name,
              // key,
              // type: 'textSelection',
              value: []
            }
          };
      }
      jsonPathObjArray.push(obj);
    });
    const mergedObject = _.merge({}, ...jsonPathObjArray);
    return mergedObject;
  });
  // eslint-disable-next-line consistent-return
  return res;
}
export function updateOutPutJson(templateData: any, changeData: any): any {
  const originData = initOutPutJson(templateData);
  try {
    for (let i = 0; i < changeData.length; i += 1) {
      const item = originData[changeData[i].index];
      Object.keys(item).forEach((key) => {
        if (item[`${key}`].key === changeData[i].key) {
          if (changeData[i].value) {
            originData[changeData[i].index][`${key}`].value =
              changeData[i].value;
          }
          if (changeData[i].children) {
            originData[changeData[i].index][`${key}`].children =
              changeData[i].children;
          }
        }
      });
    }
  } catch (err) {
    console.warn('updateOutPutJson出错', err);
  }
  return originData;
}
export function mergeDifferences(a: any, b: any): any {
  const result: any = {};

  // 为了确保所有键都被检查，需要合并a和b的键
  const keys = new Set([...Object.keys(a), ...Object.keys(b)]);

  keys.forEach((key) => {
    if (a[key] !== undefined && b[key] !== undefined) {
      // 如果两个值都是对象且不为数组，则递归比较
      if (
        typeof a[key] === 'object' &&
        typeof b[key] === 'object' &&
        !Array.isArray(a[key]) &&
        !Array.isArray(b[key])
      ) {
        result[key] = mergeDifferences(a[key], b[key]);
      } else if (a[key] === b[key]) {
        // 如果值相同，则使用a中的值
        result[key] = a[key];
      } else {
        // 如果值不同，则更新为传入的值
        result[key] = b[key];
      }
    } else if (a[key]) {
      // 只在a中存在的值
      result[key] = a[key];
    } else if (b[key]) {
      // 只在b中存在的值
      result[key] = b[key];
    }
  });

  return result;
}

// 校验字符数量：不超过10个字符
export function validateInputLength(_: any, value: any): any {
  if (value && value.length > 10) {
    return Promise.reject(new Error('最多输入10个字符'));
  }
  return Promise.resolve();
}

const validateCharacters = (_: any, value: any): any => {
  if (value && !/^[\u4e00-\u9fa5a-zA-Z0-9\p{P}]+$/u.test(value)) {
    return Promise.reject(new Error('仅支持中文、英文大小写、标点符号，数字'));
  }
  return Promise.resolve();
};

export function markPageData(editorData: any, templateData: any): any {
  try {
    const copyArray: any[] = [];
    const cloneData = _.cloneDeep(templateData).filter(
      (item: any) => !item?.config?.copyId
    );

    cloneData.forEach((item: any, index: number) => {
      const { config } = item;
      // 有动态文本展示组件
      if (config.mainReferenceId && config.rootJsonPath) {
        const jsonData = JSON.parse(editorData);
        const getData = JSONPath({
          path: config.rootJsonPath,
          json: jsonData
        })[0];
        if (getData && getData.length) {
          getData.forEach((dataItem: any, dataIndex: any) => {
            const newDataItem = _.cloneDeep(item);
            // 第一项时传入原始的 后续为copy的
            if (dataIndex === 0) {
              // 设置标注区内部动态文本展示所需要用到的json值
              const curChildDynamicShow = findDynamicShow(item.children);
              if (curChildDynamicShow) {
                curChildDynamicShow.config.realJsonPath = `${curChildDynamicShow.config.jsonPath}[${dataIndex}]`;
              }
              copyArray.push(item);
            } else {
              newDataItem.config.copyId = item.key;
              newDataItem.key = uuidv4();
              // 设置标注区内部动态文本展示所需要用到的json值
              const curChildDynamicShow = findDynamicShow(newDataItem.children);
              if (curChildDynamicShow) {
                curChildDynamicShow.config.realJsonPath = `${curChildDynamicShow.config.jsonPath}[${dataIndex}]`;
              }
              copyArray.push(newDataItem);
            }
          });
        }
      } else {
        copyArray.push(item);
      }
    });
    return copyArray;
  } catch (error) {
    console.warn('有异常 返回原始数据', error);
    return templateData;
  }
}

// 平铺输出数据中有关输出值的部分
export function EchoPageData(outputData: any): any {
  const res: any[] = [];
  if (!outputData) return res;
  for (let i = 0; i < outputData.length; i += 1) {
    if (JSON.stringify(outputData[i]) !== '{}') {
      Object.keys(outputData[i]).forEach((key: string) => {
        const obj: any = {
          index: i,
          key: outputData[i][key].key,
          value: outputData[i][key].value,
          type: outputData[i][key].type
        };
        if (
          outputData[i][key].type === 'radio' &&
          outputData[i][key].children
        ) {
          obj.children = outputData[i][key].children;
        }
        res.push(obj);
      });
    }
  }
  return res;
}

export function clearItemDataActive(list: any): any {
  if (!list) return;
  const cloneData: any = _.cloneDeep(list);
  cloneData.forEach((item: any) => {
    if (Object.hasOwn(item, 'active')) {
      delete item.active;
    }
    if (item.children) {
      item.children = clearItemDataActive(item.children);
    }
  });
  // eslint-disable-next-line consistent-return
  return cloneData;
}

// 通过templateData获取json path的schema校验
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const jsonPathSchemaToStructure = (jsonPath, type, optionSource) => {
  if (!jsonPath) return {};
  // 移除开头的$和括号内的通配符[*]
  const normalizedPath = jsonPath.replace(/^\$\./, '');

  // 分割路径
  const keys = normalizedPath.split('.');

  const regex = /(\[\d\]|\[\*\])$/;

  // 递归构建JSON结构
  // @ts-ignore
  const buildStructure = (keys, isArray, type, optionSource): any => {
    if (keys.length === 0) {
      let endData: any = {};

      if (type === 'staticShow') {
        endData = {
          type: 'string'
        };
      } else if (type === 'dynamicShow') {
        endData = {
          type: 'array',
          title: '动态文本组件内容',
          items: {
            type: 'object',
            title: '文本内容',
            required: ['value'],
            properties: {
              key: {
                type: 'string',
                title: '左上角标识'
              },
              value: {
                type: 'string',
                title: '内容'
              }
            }
          }
        };
      } else if (type === 'radio' && optionSource === 'noFixed') {
        endData = {
          type: 'array',
          title: '动态文本组件内容',
          items: {
            type: 'object',
            title: '文本内容',
            required: ['value', 'options'],
            properties: {
              options: {
                type: 'array',
                title: '二级选项集合',
                items: {
                  type: 'string',
                  title: '二级选项内容'
                }
              },
              value: {
                type: 'string',
                title: '一级选项'
              }
            }
          }
        };
      } else {
        endData = {
          type: 'object'
        };
      }
      return endData;
    }

    // 移除并获取当前的key
    let key = keys.shift();
    // 检查下一个键是否表示数组
    const currentIsArray = regex.test(key);
    // 递归构建剩余的结构
    const value = buildStructure(keys, currentIsArray, type, optionSource);
    // 构建当前层级的对象
    const obj: any = {
      type: 'object',
      properties: {}
    };

    // 清除key中的数组标记
    if (currentIsArray) {
      key = key.replace(regex, '');
      const defaultSchema: any = {
        type: 'array'
      };
      defaultSchema.items = value;
      obj.properties[key] = defaultSchema;
    } else {
      // const defaultSchema = {
      //   type: 'object',
      // };
      obj.properties[key] = value;
    }
    return obj;
  };

  // 构建JSON结构
  return buildStructure(keys, false, type, optionSource);
};

// json数据转换成json schema
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const jsonReverseSchema = (templateData: string | any[]) => {
  if (!templateData) return {};
  if (templateData.length <= 0) return {};
  const jsonPathList = getJsonPathList(templateData);
  const jsonPathObjArray: any = [];
  jsonPathList.forEach((item: any) => {
    const { type, path, optionSource } = item;
    const result = jsonPathSchemaToStructure(path, type, optionSource);
    jsonPathObjArray.push(result);
  });
  const mergedObject = _.merge({}, ...jsonPathObjArray);
  // eslint-disable-next-line consistent-return
  // return { jsonSchema: mergedObject };
  return mergedObject;
};

// 检查模版数据
export function getTemplateDataSchema(templateData: any): any {
  const returnResult = { result: true, message: '' };
  const jsonSchema = {};

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const transformStructure = (nodes: any[], isFirstLevel = true): any => {
    return nodes.map((node): any => {
      if (node.children && node.children.length > 0) {
        return {
          type: 'object',
          properties: {
            children: {
              type: 'array',
              items: transformStructure(node.children)
            }
          }
        };
      }

      return {
        type: 'object',
        properties: {
          config: {
            type: 'object',
            // @ts-ignore
            properties: schema[node.type].properties
          }
        }
      };
    });
  };

  const result = transformStructure(templateData);
  const outputJsonSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    properties: {
      components: {
        type: 'array',
        items: result
      },
      direction: {
        type: 'string'
      },
      version: {
        type: 'string'
      }
    }
  };

  const validate = ajv.compile(outputJsonSchema);
  const valid = Boolean(validate({ components: templateData }));
  if (!valid) {
    localize.zh(validate?.errors);
  }
  const errors = validate?.errors;
  const message = errors ? errors[0].message : '';

  return outputJsonSchema;
}

// 拉框计件
export const countDrawBoxList = (list: any): any => {
  if (!list || list.length === 0) {
    return {
      isCount: 0,
      total: 0,
      frameInfo: []
    };
  }
  let total = 0;
  let isFlag = 0;
  const frameInfo: any = [];

  list.forEach((item: any, index: any) => {
    if (JSON.stringify(item) !== '{}') {
      const dataValues = Object.values(item);
      const findImgAnnotation: any = dataValues.find(
        (dataItem: any) => dataItem.type === 'imgAnnotation'
      );
      if (findImgAnnotation) {
        isFlag = 1;
        const { isCount, value = [], name, key } = findImgAnnotation;

        frameInfo.push({
          key,
          index,
          name,
          flag: isCount,
          count: value.length
        });
        total += value.length;
      }
    }
  });

  return {
    isCount: isFlag,
    total,
    frameInfo
  };
};

export const clearPreGraphData = (list: any): any => {
  if (!list || list.length === 0) {
    return [];
  }
  list.forEach((item: any) => {
    if (JSON.stringify(item) !== '{}') {
      for (const key in item) {
        // 处理图像标注组件 去除预设部分的数据
        if (item[key].type === 'imgAnnotation') {
          item[key].value = item[key].value.filter(
            (itemChild: any) => !itemChild.graph.customPreData
          );
        }
      }
    }
  });
  return list;
};

// 点击预览和发布的时候修改校验对比目标是否有误
export const resetTextComparisonObject = (data: any, allData: any): any => {
  return data.map((item: any) => {
    const newData = { ...item };
    if (newData.type === 'staticShow' || newData.type === 'textEditor') {
      if (
        newData.config.textComparison !== undefined &&
        newData.config.textComparison === 1 &&
        newData.config.textComparisonObject
      ) {
        const list = getTextComparisonList(allData, newData.key);
        if (list.length > 0) {
          const originKey = JSON.parse(newData.config.textComparisonObject).key;
          let foundIndex = -1;
          for (let i = 0; i < list.length; i += 1) {
            const newKey = JSON.parse(list[i].value).key;
            if (originKey === newKey) {
              foundIndex = i;
              break;
            }
          }
          if (foundIndex !== -1) {
            newData.config.textComparisonObject = list[foundIndex].value;
          } else {
            newData.config.textComparisonObject = list[0].value;
          }
        } else {
          newData.config.textComparison = 0;
        }
      }
    }
    if (Array.isArray(newData.children)) {
      newData.children = resetTextComparisonObject(newData.children, allData);
    }
    return newData;
  });
};
