import React, { useEffect, useState } from 'react';
import { Menu, Popover, Tooltip, Button } from 'antd';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useStores } from '../../hook/index';
import './UserMenuComponent.less';
import { RouteType } from '../../route/index';

interface MenuInfo {
  title: string;
  requireQuestionFlag?: boolean;
  item: Array<{
    content: string;
    to: string;
  }>;
}

//  是否展示题库查询栏
const questionFlag = localStorage.getItem('questionFlag');

// 普通人员菜单
const COMMON = [
  {
    title: '我的任务',
    item: [
      {
        content: '标注任务',
        to: '/annotation/mark-list'
      }
    ]
  },
  {
    title: '题库管理',
    requireQuestionFlag: true,
    item: [
      {
        content: '题库查询',
        to: '/question/questionBank'
      }
    ]
  },
  // {
  //   title: '下载中心',
  //   item: [
  //     {
  //       content: '质检记录',
  //       to: '/download/inspect-log'
  //     }
  //   ]
  // },
  {
    title: '个人中心',
    item: [
      {
        content: '个人信息',
        to: '/personnel/info'
      }
    ]
  }
];
const COMMONInspect = [
  {
    title: '我的任务',
    item: [
      {
        content: '质检任务',
        to: '/annotation/inspect-list'
      }
    ]
  },
  {
    title: '题库管理',
    requireQuestionFlag: true,
    item: [
      {
        content: '题库查询',
        to: '/question/questionBank'
      }
    ]
  },
  // {
  //   title: '下载中心',
  //   item: [
  //     {
  //       content: '质检记录',
  //       to: '/download/inspect-log'
  //     }
  //   ]
  // },
  {
    title: '个人中心',
    item: [
      {
        content: '个人信息',
        to: '/personnel/info'
      }
    ]
  }
];
// 验收
const MANAGER = [
  {
    title: '业务管理',
    item: [
      {
        content: '项目管理',
        to: '/project/list'
      },
      {
        content: '工作流管理',
        to: '/project/workFlow'
      },
      {
        content: '数据统计',
        to: '/statistic/project'
      }
    ]
  },
  {
    title: '题库管理',
    item: [
      {
        content: '题库',
        to: '/question/list'
      }
    ]
  },
  {
    title: '下载中心',
    item: [
      {
        content: '质检记录',
        to: '/download/inspect-log'
      },
      {
        content: '导出数据',
        to: '/download/derived-data'
      }
    ]
  }
];
// 普通用户
const NORMAL = [
  {
    title: '业务管理',
    item: [
      {
        content: '项目管理',
        to: '/project/list'
      },
      {
        content: '工作流管理',
        to: '/project/workFlow'
      }
    ]
  },
  {
    title: '模版管理',
    item: [
      {
        content: '模版库',
        to: '/manage-template/template-library'
      }
    ]
  },
  {
    title: '数据管理',
    item: [
      {
        content: '数据检索',
        to: '/dataRetrieval/dataRetrieval'
      }
    ]
  }
];
// 运营
const MARK_OPERATION = [
  {
    title: '业务管理',
    item: [
      {
        content: '项目管理',
        to: '/project/list'
      },
      {
        content: '工作流管理',
        to: '/project/workFlow'
      },
      {
        content: '数据统计',
        to: '/statistic/project'
      }
    ]
  },
  {
    title: '模版管理',
    item: [
      {
        content: '模版库',
        to: '/manage-template/template-library'
      }
    ]
  },
  {
    title: '数据管理',
    item: [
      {
        content: '数据集',
        to: '/dataSet/list'
      },
      {
        content: '数据检索',
        to: '/dataRetrieval/dataRetrieval'
      }
    ]
  },
  // {
  //   title: '数据管理',
  //   item: [
  //     {
  //       content: '数据检索',
  //       to: '/dataRetrieval//dataRetrieval'
  //     }
  //   ]
  // },
  {
    title: '题库管理',
    item: [
      {
        content: '题库',
        to: '/question/list'
      }
    ]
  },
  {
    title: '下载中心',
    item: [
      {
        content: '质检记录',
        to: '/download/inspect-log'
      }
    ]
  }
];

// 管理员
const OPERATION = [
  ...MARK_OPERATION.filter((section) => section.title !== '下载中心'),
  ...[
    {
      title: '下载中心',
      item: [
        {
          content: '质检记录',
          to: '/download/inspect-log'
        },
        {
          content: '价格信息',
          to: '/download/price-derived'
        }
      ]
    },
    {
      title: '价格管理',
      item: [
        {
          content: '模版价格',
          to: '/price/template'
        }
      ]
    },
    {
      title: '人员管理',
      item: [
        {
          content: '成员管理',
          to: '/admin/admin-member-manage'
        },
        {
          content: '团队管理',
          to: '/admin/admin-team-management'
        }
      ]
    }
  ]
];

// 标注团队负责人
const MARK_MANAGER = [
  {
    title: '业务管理',
    item: [
      {
        content: '项目管理',
        to: '/project/list'
      },
      {
        content: '数据统计',
        to: '/statistic/project'
      }
    ]
  },
  {
    title: '人员管理',
    item: [
      {
        content: '成员管理',
        to: '/personnel/member-manage'
      },

      {
        content: '标签管理',
        to: '/personnel/tags-manage'
      },
      {
        content: '小组管理',
        to: '/personnel/team-manage'
      }

      // {
      //   content: '人力数据分析',
      //   to: '/manpower/data-analysis',
      // },
    ]
  },
  {
    title: '个人中心',
    item: [
      {
        content: '个人信息',
        to: '/personnel/info'
      }
    ]
  },
  {
    title: '下载中心',
    item: [
      {
        content: '质检记录',
        to: '/download/inspect-log'
      }
    ]
  }
];

const { SubMenu } = Menu;

const UserMenu: React.FC<{ route?: RouteType }> = ({ route }) => {
  const { user } = useStores();

  const [openKeys, setOpenKeys] = useState<Array<string>>([]);

  const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);

  const [menu, setMenu] = useState<Array<MenuInfo>>([]);

  const handleOpenChange = (
    newOpenKeys:
      | React.Key[]
      | {
          key: React.Key;
          item: React.ReactInstance;
          trigger: string;
          open: boolean;
        }
  ): void => {
    const latestOpenKey = (newOpenKeys as [string]).find(
      (key: string) => !openKeys.includes(key)
    );
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  useEffect(() => {
    let menuData = [];
    switch (user.roleCode) {
      case 'COMMON_MARK':
        menuData = COMMON;
        break;
      case 'COMMON_INSPECT':
        menuData = COMMONInspect;
        break;
      case 'GROUP_OWNER':
      case 'GROUP_ADMIN':
        menuData = MARK_MANAGER;
        break;
      case 'OPERATION':
        menuData = OPERATION;
        break;
      case 'OPERATION_COMMON':
        menuData = MARK_OPERATION;
        break;
      case 'OPERATION_COMMON_NORMAL':
        menuData = NORMAL;
        break;
      default:
        menuData = MANAGER;
    }
    setMenu(menuData);
    setOpenKeys([menuData[0].title]);
  }, [user.roleCode]);

  useEffect(() => {
    if (route && route.crumbs) {
      setOpenKeys([route.crumbs[0]]);
      setSelectedKeys([`${route.crumbs[0]}&${route.crumbs[1]}`]);
    } else {
      setOpenKeys([]);
      setSelectedKeys([]);
    }
  }, [route]);

  return (
    <div className="user-menu-container">
      <Menu
        theme="dark"
        mode="inline"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        onOpenChange={handleOpenChange}
        style={{ width: 200 }}
      >
        {menu.map(
          (menuData) =>
            (!menuData.requireQuestionFlag || questionFlag) && (
              <SubMenu key={menuData.title} title={menuData.title}>
                {menuData.item.map((itemData) => (
                  <Menu.Item key={`${menuData.title}&${itemData.content}`}>
                    <Link to={itemData.to}>
                      <div style={{ width: '100%', height: '100%' }}>
                        {itemData.content}
                        {itemData.content === '人力数据分析' && (
                          <span>
                            <Popover
                              placement="rightTop"
                              content={() => (
                                <p style={{ fontWeight: 'bold' }}>
                                  辅助标注管理员分析团队成员的标注、
                                  <br />
                                  质检、验收等作业流程效率。
                                  <br />
                                  管理者可以依据作业效率进行绩效
                                  <br /> 评定、人员奖惩等各类管理措施。
                                </p>
                              )}
                            >
                              <QuestionCircleOutlined
                                style={{ marginLeft: '8px' }}
                              />
                            </Popover>
                          </span>
                        )}
                      </div>
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            )
        )}
      </Menu>
    </div>
  );
};
export default UserMenu;
